//Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Raleway:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i');
//Variables
@import 'variables';
//Bootstrap
@import '~bootstrap/scss/bootstrap';
//Font Awesome
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/css/all.css';
//@import '~@fortawesome/fontawesome-free/scss/solid';



//GENERAl
.bodyV1    {
    overflow-y:hidden;
    font-family: "Poppins", sans-serif;
  }

.bodyV2    {
    overflow-y:scroll;
    overflow-x:hidden;
    font-family: "Poppins", sans-serif;
  }

  .container-fluid{
    padding-bottom: 0px;
    padding-top: 0px;
	padding-left:0px;
    padding-right: 0px;
}

.scroll {
    max-height: 1000px;
    overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #BBD400;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #e1ff00;
  }

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }

  .map-container{
    overflow:hidden;
    padding-bottom:650px;
    position:relative;
    height:650px;
    }   

//NAVBAR
.fa, .fas {
    padding-right: 10px !important;
}


.navbar-brand-v1{
  margin-right: 2rem !important;
}

.navbar-brand-v2{
    margin-right: 10rem !important;
}
.navbar-brand-v3{
  margin-right: 12rem !important;
}
.navbar-brand-v4 {
  margin-right: 0rem !important;
}
.navbar-brand-v5 {
  margin-right: 10rem !important;
}

.navbar-v1{
    z-index:5;
    width: 100%;
    //height: 65px;
    position: fixed;

    //line-height: 65px;
    text-align: right;
    //position:fixed;
 }

//.navbar-brand {
    //padding-left: 55px;
    //padding-right: 50px;
//}

.nav-a {
  color: #0099FF;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.15em;
  font-family: "Poppins", sans-serif;
  display: inline-block;
  position: relative;
  font-weight: normal;
  font-size: 14px;
}

.nav-av2{
  color: #BBD400 !important;
}

.nav-a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #BBD400;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}


  .nav-a-about:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #BBD400;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }

  .nav-a:hover:after {
    width: 100%;
    left: 0;
  }

  .navbar-expand-lg .navbar-nav .nav-link{
    padding-left: 20px;
    padding-right:20px;
  }

  .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }


  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }

  .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }

  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
  }

  .nav-link:hover,
  .nav-link:focus {
    text-decoration: none;
  }

  .nav-link.active {
    font-weight: bolder;
  }

  .nav-button {
        font-size: 12px;
        margin-top: 6px;
        font-weight: bolder;
        //padding-left: 1.5rem;
        //padding-right: 1.5rem;
  }

  .nav-button:hover {
    background-color: #e1ff00;
   }

  .nav-button:focus{
    color: #fff;
    background-color: #BBD400;
    border-color: #BBD400;
  }

  .nav-tabs .nav-item {
    margin-bottom: -1px;
  } 

  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #f8fafc;
    border-color: #dee2e6 #dee2e6 #f8fafc;
  }

  .nav-fill > .nav-link,
  .nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: right;
  }

  .nav-justified > .nav-link,
  .nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: right;
  }

  .collapse:not(.show) {
    display: none;
  }

  .navbar-v1 {
    z-index: 5;
    width: 100%;
    position: fixed;
    display: block;
    text-align: right;
    float: right;
  }

  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: right;
    // background: rgba(4, 9, 56, 0.7);
  }

  .navbar-collapse-register {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: right;
    // background: rgba(4, 9, 56, 0.7);
    /* position: relative; */
  }

  .justify-content-between {
    justify-content: space-between !important;
  }
  
  @media (max-width: 575.98px) {
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid,
    .navbar-expand-sm > .container-sm,
    .navbar-expand-sm > .container-md,
    .navbar-expand-sm > .container-lg,
    .navbar-expand-sm > .container-xl {
        padding-right: 0;
        padding-left: 0;
    }
    .centrarbotonquedainmueble{
      float: right;
    }
  } 
  
  
  @media (min-width: 576px) {
    .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
  
    .navbar-expand-sm .navbar-nav {
        flex-direction: row;
    }
  
    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute;
    }
  
    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .nav-link li{
        text-align: right;
    }
  
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid,
    .navbar-expand-sm > .container-sm,
    .navbar-expand-sm > .container-md,
    .navbar-expand-sm > .container-lg,
    .navbar-expand-sm > .container-xl {
        flex-wrap: nowrap;
    }
  
    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-sm-about .navbar-collapse-about {
        display: flex !important;
        flex-basis: auto;
    }
  
    .navbar-expand-sm .navbar-toggler {
        display: none;
    }
    .centrarbotonquedainmueble{
      float: right;
    }
  }
  
  @media (max-width: 767.98px) {
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid,
    .navbar-expand-md > .container-sm,
    .navbar-expand-md > .container-md,
    .navbar-expand-md > .container-lg,
    .navbar-expand-md > .container-xl {
        padding-right: 0;
        padding-left: 0;
    }
    .centrarbotonquedainmueble{
      float: right;
    }
  }
  
  @media (min-width: 768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
  
    .navbar-expand-md .navbar-nav {
        flex-direction: row;
    }
  
    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
    }
  
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
  
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid,
    .navbar-expand-md > .container-sm,
    .navbar-expand-md > .container-md,
    .navbar-expand-md > .container-lg,
    .navbar-expand-md > .container-xl {
        flex-wrap: nowrap;
    }
  
    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
        background: transparent;
    }
  
    .navbar-expand-md-about .navbar-collapse-about {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-md-register .navbar-collapse-register {
        display: flex !important;
        flex-basis: auto;
        background: transparent;
    }
  
    .navbar-expand-md .navbar-toggler {
        display: none;
    }
    .centrarbotonquedainmueble{
      float: right;
    }
  }
  
  @media (max-width: 991.98px) {
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid,
    .navbar-expand-lg > .container-sm,
    .navbar-expand-lg > .container-md,
    .navbar-expand-lg > .container-lg,
    .navbar-expand-lg > .container-xl {
        padding-right: 0;
        padding-left: 0;
    }
    .centrarbotonquedainmueble{
      float: right;
    }
    .ocultarPasosInmueblePC{       
      display: none;
  }
  }
  
  @media (min-width: 992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
  
    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }
  
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }
  
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
  
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid,
    .navbar-expand-lg > .container-sm,
    .navbar-expand-lg > .container-md,
    .navbar-expand-lg > .container-lg,
    .navbar-expand-lg > .container-xl {
        flex-wrap: nowrap;
    }
  
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
        background: transparent;
    }
  
    .navbar-expand-lg .navbar-collapse-about {
        display: flex !important;
        flex-basis: auto;
        background: white;
    }
    .navbar-expand-lg .navbar-collapse-register {
        display: flex !important;
        flex-basis: auto;
        background: transparent;
    }
    #crearInmueble-about{
        background: white;
    }
    /* .navbar-collapse-about {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: right;
        background: rgba(76, 175, 80, 0.3);
        position: relative;
    } */
  
    .navbar-expand-lg-about .navbar-collapse-about {
        display: flex !important;
        flex-basis: auto;
    }
  
    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
    .centrarbotonquedainmueble{
      float: right;
    }
    .ocultarPasosInmuebleMovil{       
      display: none;
  }
  }
  
  @media (max-width: 1199.98px) {
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid,
    .navbar-expand-xl > .container-sm,
    .navbar-expand-xl > .container-md,
    .navbar-expand-xl > .container-lg,
    .navbar-expand-xl > .container-xl {
        padding-right: 0;
        padding-left: 0;
    }
    .centrarbotonquedainmueble{
      float: right;
    }
  }
  
  @media (min-width: 1200px) {
    .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
  
    .navbar-expand-xl .navbar-nav {
        flex-direction: row;
    }
  
    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
    }
  
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
  
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid,
    .navbar-expand-xl > .container-sm,
    .navbar-expand-xl > .container-md,
    .navbar-expand-xl > .container-lg,
    .navbar-expand-xl > .container-xl {
        flex-wrap: nowrap;
    }
  
    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
        background: transparent;
    }
  
    .navbar-expand-xl-about .navbar-collapse-about {
        display: flex !important;
        flex-basis: auto;
    }
  
    .navbar-expand-xl-register .navbar-collapse-register {
        display: flex !important;
        flex-basis: auto;
        background: transparent;
    }
  
    .navbar-expand-xl .navbar-toggler {
        display: none;
    }
    .centrarbotonquedainmueble{
      float: right;
    }
  }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
  background: transparent;
}

.navbar-expand-about .navbar-collapse-about {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand-register .navbar-collapse-register {
  display: flex !important;
  flex-basis: auto;
  background: transparent;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark-about{
  color: #fff;
}

.navbar-dark-about:hover{
  color: goldenrod;
}

.navbar-dark-about .navbar-toggler-icon-about {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-brand {
  color: #0066FF;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #bbd400;
}

.navbar-dark .navbar-nav .nav-link {
  /* color: rgba(255, 255, 255, 0.5); */
  color: #0066FF;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  /* color: rgba(255, 255, 255, 0.75); */
  color: #bbd400;
}

.navbar-dark .navbar-nav .nav-link.disabled {
  /* color: rgba(255, 255, 255, 0.25); */
  color: #0066FF;
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #0066FF;
}

.navbar-dark .navbar-toggler {
  /* color: rgba(255, 255, 255, 0.5); */
  color: #0066FF;
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  /* color: rgba(255, 255, 255, 0.5); */
  color: #0066FF;
}

.navbar-dark .navbar-text a {
  color: #0066FF;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  /* color: #fff; */
  color: #bbd400;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-left: 20px;
  padding-right: 20px;
}

//LANDING PAGE

.vertical-alignment-helper {
    display:table;
    height: 100%;
    width: 100%;
    pointer-events:none;}

.vertical-align-center {
    /* To center vertically */
    display: table-cell;
    vertical-align: middle;
    pointer-events:none;
    }

.modal{
    position: absolute !important;
}

.modal-content {
    /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */
    width:inherit;
    max-width:inherit; /* For Bootstrap 4 - to avoid the modal window stretching
    full width */
    height:inherit;
    /* To center horizontally */
    margin: 0 auto;
    pointer-events:all;}

.parent-fullscreen{
    //position:absolute;
    z-index:3;
    width:100%;
    height:100%;
    position:fixed;
    filter: blur(6px);
  -webkit-filter: blur(6px);
  }

.filtros{
    color:#fff;
  }

.zonas{
    color:#fff;
  }

  .checkbox {
    width: 100%;
    margin: 15px auto;
    position: relative;
    display: block;
}

  .checkbox input[type="checkbox"] {
    width: auto;
    opacity: 0.00000001;
    position: absolute;
    left: 0;
    margin-left: -20px;
}
.checkbox label {
    position: relative;
}
.checkbox label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    margin: 4px;
    width: 22px;
    height: 22px;
    transition: transform 0.28s ease;
    border-radius: 3px;
    border: 2px solid #BBD400;
}
.checkbox label:after {
  content: '';
    display: block;
    width: 10px;
    height: 5px;
    border-bottom: 2px solid #BBD400;
    border-left: 2px solid #BBD400;
    -webkit-transform: rotate(-45deg) scale(0);
    transform: rotate(-45deg) scale(0);
    transition: transform ease 0.25s;
    will-change: transform;
    position: absolute;
    top: 12px;
    left: 10px;
}
.checkbox input[type="checkbox"]:checked ~ label::before {
    color: #BBD400;
}

.checkbox input[type="checkbox"]:checked ~ label::after {
    -webkit-transform: rotate(-45deg) scale(1);
    transform: rotate(-45deg) scale(1);
}

.checkbox label {
    min-height: 34px;
    display: block;
    padding-left: 40px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
    vertical-align: sub;
}
.checkbox label span {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.checkbox input[type="checkbox"]:focus + label::before {
    outline: 0;
}

.hide{
    display:none;
  }
//FORMS

.error{
    color: #b40300;
    background-color:#ffb1b1;
    border-radius: 20px;
}

.container-forms{
    width: 50% !important;}

.btn-success {
    background-color:#BBD400;
    border: none;
    color: white;
    padding: 5px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 20px;
  }

  .btn-danger {
    background-color:#d40000;
    border: none;
    color: white;
    padding: 5px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 20px;
  }

.btn-forms{
    background-color:#BBD400;
    border: none;
    color: white;
    padding: 5px 40px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 20px;
}

.input-moneda{
    border-top-left-radius: 0px 0px !important;
    border-bottom-left-radius: 0px 0px !important;
}

.input-group-text{
    border:0px solid #fff !important;
    border-top-left-radius: 20px 20px !important;
    border-bottom-left-radius: 20px 20px !important;
}


//IMG
.img-container {
    position: relative;
    text-align: center;
    color: white;
    padding-left: 0px;
    padding-right: 0px;
  }

  .img-fluid{
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2));
    width: 100%;
    height: 100%;
  }

  .top-left {
    position: absolute;
    top: 8px;
    left: 16px;
  }

  .bottom-left {
    position: absolute;
    bottom: 8px;
    left: 16px;
  }

  .centered {
    position: absolute;
    top: 42%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight:bolder;
  }

  .img-gradient{
    position:relative;
    display:inline-block;

  }

  /*
  #002f4b,#dc4225
  Convert HEX to RGBA - http://hex2rgba.devoth.com/
  */
  .img-gradient:after {
    content:'';
    position:absolute;
    left:0; top:0;
    width:100%; height:100%;
    display:inline-block;
    background: -moz-linear-gradient(top, rgba(0,47,75,0.5) 0%, rgba(220, 66, 37, 0.5) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(220, 66, 37, 0.5)), color-stop(100%,rgba(0,47,75,0.5))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* IE10+ */
    background: linear-gradient(to bottom, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
  }

  .img-gradient img{
    display:block;
  }

  //FORMS

.card{
    border-radius: 15px!important;
    outline: none;
}

.card-childs{
    width: 22rem;
    height: 33rem;
}

.card-parent {
    width: 20rem;
    height: 25rem;
}

.card-img-top-child{
    width: 100%;
    height: 15vw;
    object-fit: cover;
}

.card-img-top-parent{
    width: 100%;
    height: 37%;
    -o-object-fit: cover;
    object-fit: cover;
}

.carousel-control-prev {
    left: -2.5rem;
}

.carousel-control-next {
    right: -2rem;
}

.scrollable{
    overflow-y: auto;
    max-height: 6rem;
    min-height: 6rem;
  }

  .scrollable-forms{
    overflow-y: auto;
    max-height: 6rem;
    min-height: 5rem;
  }

.card-img-top-child{
    width: 100%;
    height: 15vw;
    object-fit: cover;
}

.card-img-top-forms{
    width: 100%;
    height: 200px;
    -o-object-fit: cover;
    object-fit: cover;
}

#carousel-childs-detalle-img{
    width: 100%;
    // height: 45em;
    -o-object-fit: cover;
    object-fit: cover;
    padding-bottom: 10px;
}

.btn-forms:hover {
    background-color: #e1ff00;
  }

//h1, h2{
    //color: #BBD400;
    //font-family: "Poppins", sans-serif;
    //font-weight: bolder;
//}

#address-map {
    height: 400px;
    left: 0;
    top: 0;
    overflow: hidden;
}
select{
    border-radius: 15px !important;
    border: none !important;
    outline: none !important;
}

input {
    border-radius: 15px !important;
    border: none !important;
    outline: none !important;
}

.form-control:focus{
    box-shadow: 0 0.5rem 1rem rgba(187, 212, 0, 0.15) !important;
    overflow: scroll;}

.modal-header {
    border-bottom: 0px solid #dee2e6 !important;
}

.modal-footer {
    border-top: 0px solid #dee2e6 !important;
}

.footer-bottom {
    background-color: #BBD400 !important;
}


//INMUEBLE
.inmueble-a {
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 0.15em;
    font-family: "Poppins", sans-serif;
    display: inline-block;
    font-weight: bolder !important;
    position: relative;
    font-weight: normal;
    font-size: 25px;
    padding-top: 25px;
  }

.inmueble-p {
    font-weight: normal;
  }

.inmueble-a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #BBD400;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  .inmueble-a:hover:after {
    width: 100%;
    left: 0;
  }

  .inmueble-a:hover {
    color:#fff;
    text-decoration: none;
    cursor:pointer;
}
  .inmueble-centered {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .inmueble-img-fluid{
    max-width: 100%;
    height: 120%;
  }

  .btn-card-inmueble{
    padding: 0px 20px;
  }


//INMUEBLE DETALLE
  .inmueble-det-img-fluid{
    max-width: 100%;
    height: 120%;
  }

  .icon{
    color:#BBD400;
    padding-right: 2px !important;
    padding-left: 10px !important;
  }

  .inmueble-det-centered {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight:bolder;
  }

  .thumbnail {
    max-height: 200px;
    max-width: 325px;
    object-fit: contain;
}

  .inmueble-det-a {
    font-weight: normal !important;
    font-size: 14px;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
//FOOTER

.font-normal{
font-weight: normal;
}
.font-bold{
font-weight: bolder;
}

.footer {
    position: absolute;
    bottom: 0;
    //width: 100vw;
    color: white;
    height: 150px;
    display: flex;
    align-items: flex-start;;
    justify-content: left;
    background: transparent;
    opacity: 1;
  }

  .h1-footer{
    font-family: "Poppins", sans-serif;
    font-weight: bolder;
    font-size: 25px;

  }

  .social:hover {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -o-transform: scale(1.1);
}
.social {
    -webkit-transform: scale(0.8);
    /* Browser Variations: */

    -moz-transform: scale(0.8);
    -o-transform: scale(0.8);
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
}

/*
   Multicoloured Hover Variations
*/

#social-fb:hover {
    color: #3B5998;
}
#social-tw:hover {
    color: #4099FF;
}
#social-gp:hover {
    color: #d34836;
}
#social-em:hover {
    color: #f39c12;
}

//STEPPER
.stepwizard-step p {
    margin-top: 12px;
}
.stepwizard-row {
    display: table-row;
}
.stepwizard {
    display: table;
    width: 100%;
    position: relative;
}
.stepwizard-step button[disabled] {
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
}
.stepwizard-row:before {
    top: 16px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 100%;
    height: 0px;
    // background-color: #ccc;
    z-order: 0;
}
.stepwizard-step {
    display: table-cell;
    text-align: center;
    position: relative;
    padding-right: 5px;
    padding-left: 5px;
}

.btn-circle {
    width: 33px;
    height: 33px;
    text-align: center;
    padding: 6px 0;
    font-size: 0.60rem;
    line-height: 1.428571429;
    border-radius: 15px;
}

.btn-primary {
    color: #fff;
    background-color: #BBD400;
    border-color: #BBD400;
}

.btn-primary:hover {
    color: #fff;
    background-color: #e1ff00;
    border-color: #e1ff00;
  }

.btn-primary:focus {
    color: #fff;
    background-color: #BBD400;
    border-color: #BBD400;
}

.btn-primary:active{
    color: #fff;
    background-color: #BBD400;
    border-color: #BBD400;
}

//BUSQUEDA
.busqueda {
    background-color: #BBD400;
}

.busqueda-detalle {
    // padding-top: 50px;
    color: #fff;
}

.busqueda-p{
    color: #fff;
}

.busqueda-precio{
    padding-right: 90px;
}

.busqueda-img{
    margin: 10px 0px 10px;
}

.busqueda-divider  {
    border: 1px solid white;
}

.btn-busqueda{
    background-color:#fff;
    color: #BBD400;
}

.busqueda-detalle-child {
    background-color: #FFF;
}

.card-detalle-child{
    border-radius: 0px!important;
    outline: none;
}

.busqueda-detalle-child-text{
    color: #5c5c5c;
}

.list-group-item {
    padding: 0rem 0rem !important;
}

//SIDEBAR
.sidebar {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    opacity: 0.9;
  }

  .sidebar-busqueda {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 6;
    top: 0;
    right: 0;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    //opacity: 0.8;
    background: rgba(0,0,0,0.6);
  }
  .sidebar-busqueda .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    color: #fff;
    cursor: pointer;
    font-size: 20px !important;
  }

  .sidebar-busqueda2 {
    height: 380px;
    width: 0;
    position: fixed;
    z-index: 6;
    border-radius: 0px;
    top: 6%;
    left: 1%;
    background-color: #fff;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 10px;
    //opacity: 0.8;
    //background: rgba(0,0,0,0.6);
  }

  .sidebar-busqueda2-mapa {
    height: 50%;
    position: fixed;
    z-index: 6;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    top: 215px;
    left: 0.5%;
    background-color: #fff;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 10px;
}
  // .sidebar-busqueda2-mapa {
  //   height: 300px;
  //   width: 0;
  //   position: fixed;
  //   z-index: 6;
  //   border-radius: 0px;
  //   top: 15%;
  //   right: 1%;
  //   background-color: #fff;
  //   overflow-x: hidden;
  //   transition: 0.5s;
  //   padding-top: 10px;
  //   width: 20%;
  //   // padding-right: 30px;
  //   //opacity: 0.8;
  //   //background: rgba(0,0,0,0.6);
  // }

  .leaflet-verticalcenter {
    position: absolute;
    z-index: 1000;
    pointer-events: none;
    top: -2%; /* possible because the placeholder's parent is the map */
    left: 0%;
    transform: translateY(-50%); /* using the CSS3 Transform technique */
    padding-top: 10px;
}

.leaflet-verticalcenter .leaflet-control {
  margin-bottom: 10px;
  top: 102px;
  left: -10px
}

  .ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
    //border-top-left-radius: 3px;
    background: #e1ff00 !important;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
    border: 1px solid #c5c5c5;
    background: #fff !important;
    font-weight: normal;
    border-radius: 100% !important;
}

.overlayMap {
  height: auto;
  width: 135px;
  // margin-left: -5px;
  position: absolute;
  float: left;
  z-index: 2;
  pointer-events: auto;
  /* left: 50px; */
  //  bottom: 350px;
  top: 160px;
  left: -30px;
  padding-left: 20px;
}

  .stop-scrolling {
    height: 100% !important;
    overflow: hidden !important;
}

.stop-scrolling_x {
    height: 100% !important;
    overflow-x: hidden !important;
}

  .sidebar a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #adb5bd;
    display: block;
    transition: 0.3s;
  }

  .sidebar a:hover {
    color: #f1f1f1;
  }

  .sidebar .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }

  .openbtn {
    font-size: 20px;
    cursor: pointer;
    background-color: #111;
    color: white;
    padding: 10px 15px;
    border: none;
  }

  .openbtn:hover {
    background-color: #444;
  }


  /* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
    .sidebar {padding-top: 15px;}
    .sidebar a {font-size: 18px;}
  }

//Busqueda carousel child
.carousel-child-left {
    left: -130px;
    color:#FFF;
}

.carousel-child-right {
    right: -130px;
    color:#FFF;
}

//Vertical step process
.wrapper {
    // width: 95%;
    font-family: 'Helvetica';
    font-size: 0.75em;
    border: 0px solid #CCC;
  }

  .StepProgress {
    position: relative;
    padding-left: 40px;
    list-style: none;
  }
  .StepProgress::before {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    left: 15px;
    width: 10px;
    height: 100%;
    border-left: 0px solid #CCC;
  }
  .StepProgress-item {
    position: relative;
    counter-increment: list;
  }
  .StepProgress-item:not(:last-child) {
    padding-bottom: 20px;
  }
  .StepProgress-item::before {
    display: inline-block;
    content: '';
    position: absolute;
    left: -30px;
    height: 100%;
    width: 10px;
  }
  .StepProgress-item::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: -39px;
    width: 20px;
    height: 20px;
    border: 2px solid #CCC;
    border-radius: 50%;
    background-color: #FFF;
  }
  .StepProgress-item.is-done::before {
    border-left: 2px solid #e1ff00;
  }
  .StepProgress-item.is-done::after {
    //content: "â";
    font-size: 10px;
    color: #FFF;
    text-align: center;
    border: 10px solid #e1ff00;
    background-color:#e1ff00;
  }
  .StepProgress-item.current::before {
    border-left: 2px solid #e1ff00;
  }
  .StepProgress-item.current::after {
    content: counter(list);
    padding-top: 1px;
    width: 19px;
    height: 18px;
    top: -4px;
    left: -40px;
    font-size: 14px;
    text-align: center;
    color:#e1ff00;
    border: 2px solid #e1ff00;
    background-color: white;
  }
  .StepProgress strong {
    display: block;
  }


  //RETROALIMENTACION
  textarea {
    resize: none;
}

#count_message1 {
  background-color: gainsboro;
  margin-top: -20px;
  margin-right: 5px;
}

#count_message2 {
    background-color: gainsboro;
    margin-top: -20px;
    margin-right: 5px;
  }

.btn-disabled,
.btn-disabled[disabled] {
  cursor: default !important;
  pointer-events: none;
}

//PAGINACION
//.item-listing
//.item-single {
//    display: block;
//}

.nav-arrows {
    li {
        float: left;
        width: 4em;
        height: 3.6em;
        border: 0px solid #000;
        text-align: center;
        list-style: none;
        &.fs-pagi-disabled {
            pointer-events: none;
            background: #dadada;
            opacity: 0.3;
        }
        &:hover {
            background-color: #BBD400;

            a {
                color: #fff;
            }
        }

        &:first-child {
            border-radius: 30px 0 0 30px;
        }

        &:last-child {
            border-left: none;
            border-radius: 0 30px 30px 0;
        }

        a {
            display: block;
            color: #dadada;
            line-height: 55px;
            font-size: 26px;
        }
    }
}

.page-count, .total-page-count {
    position: relative;
    top: 0px;
    right: 0px;
    color: #a0a0a0;
}

.pagi-disabled {
    pointer-events: none;
    background: #dadada;
    opacity: 0.3;
}


.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.imagen{
  width: 100%;
}

.imagen img{
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2));
}

.tituloUno{
  padding-left:34%; 
  top: 30%
}

.tituloDos{
  padding-left:33%; 
  top: 80%
}

.galeria{
  width: 100%;
  margin: auto;
  display: grid;
  /* grid-template-columns: repeat(1,lfr); */
  grid-template-columns: repeat(2, 50%);
}

@media (min-width: 100px){
  .galeria{
      grid-template-columns: repeat(1, 100%);
    }
}

@media (min-width: 520px){
  .galeria{
      grid-template-columns: repeat(1, 100%);
    }
}

@media (min-width: 768px){
  .galeria{
      grid-template-columns: repeat(1, 100%);
    }
}

@media (min-width: 992px) {
  .galeria{
      /* grid-template-columns: repeat(2, 50%); */
      grid-template-columns: 50% 50%;
    }
  .tituloUno{
      padding-left:9%; 
      top: 33%
  }
  .tituloDos{
      padding-left:57%; 
      top: 33%
  }
}

@media (min-width: 1024px){
  .galeria{
      /* grid-template-columns: repeat(2, 50%); */
      grid-template-columns: 50% 50%;
  }
  .tituloUno{
      padding-left:9%; 
      top: 33%
  }
  .tituloDos{
      padding-left:57%; 
      top: 33%
  }
}

@media (min-width: 1600px){
  .galeria{
      /* grid-template-columns: repeat(2, 50%); */
      grid-template-columns: 50% 50%;
  }
  .tituloUno{
      padding-left:9%; 
      top: 33%
  }
  .tituloDos{
      padding-left:57%; 
      top: 33%
  }
}